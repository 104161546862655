<template>
  <div class="flex flex-col h-full w-full">
    <div class="p-8 pb-0">
      <base-logo filename="singular-rank-logo-horizontal.svg" alt="SingularRank" custom="pb-4" />
    </div>
    <div class="flex flex-grow items-center justify-center bg-404">
      <div class="bg-white rounded-lg shadow-2xl w-full md:w-1/2 xl:w-1/3 p-6 text-center">
        <img src="@/assets/img/error-404-message.svg" class="mx-auto pt-4 pb-4">
        <h2 class="text-3xl font-bold mb-1">{{ $t('views.404.title') }}</h2>
        <p class="mb-4">{{ $t('views.404.message') }}</p>
        <base-button @click="goHome" class="w-full" type="submit" :text="$t('views.404.button')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    goHome: function () {
      this.$router.push({ name: 'dashboard' })
    }
  }
}
</script>
<style lang="scss" scoped>
  .bg-404 {
    background-image: url('~@/assets/img/404-text.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
</style>
